<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-friend-layout">
      <div class="if-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('inviteFriend.i_inviteFriend')" @back="$router.push('/index')" style="float: left;" />
        <div style="float: right;background-color: rgba(222, 231, 233, 1);padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/common/goldIcon.png" style="width: 14px;vertical-align: middle;" />
          <span style="padding-right: 2px;font-size: 14px;color: black;text-decoration: none;vertical-align: middle;"> {{$store.state.goldNum}}</span>
        </div>
      </div>

      <div style="position: relative;">
        <img src="@/assets/images/index/inviteEvent.gif" @click="inviteEventDrawer = true" width="80" style="position: absolute;box-sizing: border-box;left: 76%;margin-top: 1%;" />
      </div>

      <div style="margin-top: 8%;">
        <img src="@/assets/images/inviteFriend001.png" style="width: 140px;vertical-align: bottom;" />
        <div style="">
          <span style="background-color: rgba(222, 231, 233, 1);padding: 10px 20px;border-radius: 30px;">
            <span style="font-size: 14px;color: black;">{{$t('inviteFriend.i_inviteT')}}</span>
          </span>
        </div>
      </div>

      <div style="margin-top: 6%;">
        <div style="font-size: 18px;">
          <span style="font-size: 15px;">{{$t('inviteFriend.i_reward')}}</span>
          &nbsp;<span style="font-weight: bold;">{{commission}}%</span>&nbsp;
          <span style="font-size: 15px;">{{$t('inviteFriend.i_commissionT')}}</span>
        </div>
        <div style="font-size: 16px;font-weight: bold;">
          {{$t('inviteFriend.i_total')}}
          {{inviteReward+totalReward}}
          <img style="width: 15px;margin-left: 1px;" src="@/assets/common/goldIcon.png" />
        </div>
      </div>

      <div style="overflow: hidden;background-color: rgba(222, 231, 233, 1);margin-top: 5%;margin-bottom: 3%;margin-left: 10%;margin-right: 10%;padding: 3%;font-size: 21px;border-radius: 8px;">
        <span style="float: left;">{{$t('inviteFriend.i_friends')}}&nbsp;{{inviteNum.inviteNum}}</span>
        <span style="float: right;margin-top: 1%;">
          <img src="@/assets/common/goldIcon.png" style="width: 16px;margin-left: 1px;" />
          {{inviteNum.totalGoldNum}}
        </span>
      </div>

      <div style="background-color: rgba(98, 98, 237, 1);margin-top: 10%;padding-top: 8%;padding-bottom: 4%;border-radius: 8px;">
        <div>
          <span style="position: relative;">
            <img width="110" src="@/assets/images/inviteFriend003.png" />
            <span style="position: absolute;box-sizing: border-box;left: 12px;font-size: 13px;width: 90px;top: -65px;bottom: 0;">{{$t('inviteFriend.i_text1')}}</span>
            <span style="position: absolute;box-sizing: border-box;left: 12px;font-size: 13px;width: 90px;top: 20px;color: white;">Step 1</span>
          </span>

          <span style="position: relative;">
            <img width="110" src="@/assets/images/inviteFriend003.png" style="margin-right: 8px;margin-left: 8px;" />
            <span style="position: absolute;box-sizing: border-box;left: 18px;font-size: 13px;width: 90px;bottom: 35px;">{{$t('inviteFriend.i_text2')}}</span>
            <span style="position: absolute;box-sizing: border-box;left: 20px;font-size: 13px;width: 90px;top: 20px;color: white;">Step 2</span>
          </span>

          <span style="position: relative;">
            <img width="110" src="@/assets/images/inviteFriend005.png" style="" />
            <span style="position: absolute;box-sizing: border-box;left: 10px;font-size: 13px;width: 90px;bottom: 30px;">
              {{$t('inviteFriend.i_text3_1')}}{{commission}}%{{$t('inviteFriend.i_text3_2')}}
            </span>
            <span style="position: absolute;box-sizing: border-box;left: 12px;font-size: 13px;width: 90px;top: 20px;color: white;">Step 3</span>
          </span>
        </div>

        <div v-if="isActivation == true" class="invite-code" style="margin-top: 10%;color: white;">
          {{$t('inviteFriend.i_code')}}：
          <span class="invite-code-text">
            <span @click="copyVal($event.target.innerText)" style="color: white;">{{inviteCode}}</span>
            <span @click="copyVal($event.currentTarget.previousElementSibling.innerText)" style="margin-left: 2%;">
              <img src="@/assets/common/copyIconW.png" style="width: 10px;margin-bottom: 8px;" />
            </span>
          </span>
        </div>

        <div style="width: 300px;position: relative;margin: auto;margin-top: 5%;">
          <el-button style="background-color: rgba(253, 251, 28, 1);width: 40%;color: black;border-radius: 30px;" type="primary" @click="tipsDialog = true">
            {{$t('inviteFriend.i_inviteNow')}}
          </el-button>
          <span @click="$router.push('/inviteLog')">
            <svg style="margin-left: 20px;vertical-align: middle;" t="1655965911443" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2350" width="32" height="32">
              <path d="M783.53 240.47C641.08 98.02 413.8 91.41 263.09 220.03V167.1c0-17.67-14.33-32-32-32s-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32s-14.33-32-32-32h-48.15c125.55-101.54 310.66-94.06 427.34 22.62 124.75 124.77 124.75 327.8 0 452.56-124.78 124.75-327.78 124.75-452.56 0C225.28 677.84 192 597.48 192 512c0-17.67-14.33-32-32-32s-32 14.33-32 32c0 102.58 39.94 199.02 112.47 271.53 74.86 74.86 173.19 112.3 271.53 112.3 98.33 0 196.67-37.44 271.53-112.3 149.7-149.72 149.7-393.34 0-543.06z" p-id="2351" fill="#ffffff"></path>
              <path d="M512 288c-17.67 0-32 14.33-32 32v185.38c0 12.81 5 24.88 14.06 33.94l123.31 123.31c6.25 6.25 14.44 9.38 22.62 9.38s16.38-3.12 22.62-9.38c12.5-12.5 12.5-32.75 0-45.25L544 498.75V320c0-17.67-14.33-32-32-32z" p-id="2352" fill="#ffffff"></path>
            </svg>
          </span>
        </div>

        <div style="margin-top: 8%;margin-bottom: 6%;">
          <router-link style="text-decoration: none;" to="">
            <span @click="centerDialogVisible = true">
              <svg t="1655967125170" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3561" width="13" height="13">
                <path d="M336.4864 711.8848a26.0096 26.0096 0 0 1-26.0096-27.0336l4.9152-125.952a25.8048 25.8048 0 0 1 7.5776-17.408L809.1648 55.7056a122.88 122.88 0 0 1 81.92-36.4544 109.1584 109.1584 0 0 1 81.92 31.1296 107.1104 107.1104 0 0 1 31.1296 81.92 122.88 122.88 0 0 1-36.4544 81.92l-486.8096 485.376a25.8048 25.8048 0 0 1-17.408 7.5776l-125.952 4.9152z m125.952-30.72z m-95.4368-109.9776l-3.4816 87.8592L450.56 655.36 930.2016 176.7424a71.2704 71.2704 0 0 0 20.48-47.3088 54.6816 54.6816 0 0 0-58.5728-58.5728 71.2704 71.2704 0 0 0-47.3088 20.48z" p-id="3562" fill="white"></path>
                <path d="M801.5872 1004.9536H171.008A150.528 150.528 0 0 1 20.48 854.4256V223.8464a150.528 150.528 0 0 1 150.528-150.528h413.2864a26.0096 26.0096 0 0 1 0 51.8144H171.008a98.7136 98.7136 0 0 0-98.5088 98.5088v630.784a98.7136 98.7136 0 0 0 98.5088 98.5088h630.5792a98.7136 98.7136 0 0 0 98.5088-98.5088V440.9344a26.0096 26.0096 0 1 1 51.8144 0v413.4912a150.528 150.528 0 0 1-150.3232 150.528z" p-id="3563" fill="white"></path>
              </svg>
              <span style="color: white;"> {{$t('inviteFriend.i_enterCode')}}</span>
            </span>
          </router-link>
        </div>
      </div>

      <el-dialog :title="$t('inviteFriend.i_enterCode')" :visible.sync="centerDialogVisible" width="80%" center>
        <el-form style="text-align: center;">
          <el-form-item :label="$t('inviteFriend.i_friendCode')" prop="inviteCode">
            <span style="float:left">
              <span style="font-size: 20px;color:rgba(0, 183, 0, 1);">+{{beInviteReward}}</span>
              <img style="width: 15px;margin-left: 1px;" src="@/assets/common/goldIcon.png" />
            </span>
            <el-input type="text" v-model="inviteForm.inviteCode" :placeholder="$t('inviteFriend.i_code1')" />
          </el-form-item>
          <el-form-item>
            <el-button round v-if="inviteUidInviteCode==null" type="primary" @click="getItNow()">
              {{$t('inviteFriend.i_claimNow')}}
            </el-button>
            <el-button round v-else type="info">{{$t('inviteFriend.i_youHaveBeenInvited')}}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-drawer size="40%" direction="btt" :with-header="false" :show-close="false" :visible.sync="drawer">
        <div>
          <div style="overflow: hidden;margin-top: 5%;margin-left: 5%;margin-right: 5%;">
            <img v-if="inviteFriend.webAppId == 1" src="@/assets/images/invite/PixelWeaponMergeIcon.png" style="float: left;height: 50px;border-radius: 10px;" />
            <img v-if="inviteFriend.webAppId == 6" src="@/assets/images/invite/GemsAndBlocksIcon.png" style="float: left;height: 50px;border-radius: 10px;" />
            <img v-if="inviteFriend.webAppId == 13" src="@/assets/images/invite/MonsterMergeIcon.png" style="float: left;height: 50px;border-radius: 10px;" />
            <span style="float: left;margin-top: 5%;margin-left: 5%;font-weight: bold;">{{inviteFriend.appName}}</span>
          </div>
          <div style="background-color: rgba(223, 223, 227, 1);margin: 5%;padding-top: 3%;padding-bottom: 3%;border-radius: 6px;">
            <span style="">
              <!-- Share to you a platform【My Cash City】that you can play games and earn money... -->
              <a :href="inviteLink">{{this.inviteLink}}</a>
            </span>
          </div>
          <div>
            <el-button round type="primary" @click="copyVal($event.currentTarget.parentElement.previousElementSibling.firstElementChild.innerText)" style="width: 38%;background-color: rgba(0, 111, 255, 1);">
              <img src="@/assets/common/copyIconW.png" style="width: 10px;" />
              &nbsp;
              <span style="font-size: 16px;">Copy</span>
            </el-button>
          </div>
        </div>
      </el-drawer>

      <el-dialog :title="$t('convert.i_tips')" :visible.sync="tipsDialog" width="85%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
        <div style="margin-bottom: 6%;text-align: center;">
          <div style="font-size: 16px;color: #000;white-space: pre-wrap;word-break: break-word;">{{$t('inviteFriend.i_tipsDialogMsg')}}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="tipsDialog = false;drawer = true;" style="width: 60%;">
            {{$t('convert.i_ok')}}
          </el-button>
        </div>
      </el-dialog>

      <el-drawer size="90%" direction="btt" :with-header="false" :show-close="false" :visible.sync="inviteEventDrawer">
        <div>
          <div style="overflow: hidden;margin-left: 3%;margin-right: 3%;margin-top: 2%;height: 48px;">
            <div @click="$router.push('/leaderboard')" style="float: left;">
              <img src="@/assets/images/index/leaderboard.png" style="height: 28px;" />
              <div style="font-size: 12px;">{{$t('index.i_leaderboard')}}</div>
            </div>
            <img src="@/assets/images/indexInviteEvent002.png" style="margin-top: 10px;width: 200px;" />
            <div @click="inviteEventDrawer = false" style="float: right;padding-top: 4px;">
              <img src="@/assets/images/index008.png" style="width: 30px;" />
            </div>
          </div>

          <hr />

          <div style="margin: 1% 2%;overflow-y: scroll;height: 78vh;">
            <div v-for="(inviteConfig, i) in inviteConfig" :key="i" :class="getStyle(i, getPercentage(inviteConfig.startNum, inviteConfig.inviteNum))" style="overflow: hidden;padding: 3% 4%;">
              <div style="float: left;">
                <div v-if="getPercentage(inviteConfig.startNum, inviteConfig.inviteNum) == 0 && inviteConfig.getReward != true" style="position: relative;width: 94px;">
                  <img src="@/assets/images/indexInviteEvent003.gif" width="80" style="margin-top: 20px;opacity: 0.6;" />
                  <span style="position: absolute;box-sizing: border-box;left: 20px;top: 58px;font-size: 12px;color: white;">
                    <span style="vertical-align: middle;font-weight: bold;">{{inviteConfig.reward}} <img src="@/assets/common/moneyIcon.png" width="12px" style="vertical-align: middle;" /></span>
                  </span>
                </div>
                <div v-else-if="inviteConfig.getReward == true" style="position: relative;width: 94px;">
                  <img src="@/assets/images/indexInviteEvent005.gif" height="84" style="" />
                  <span style="position: absolute;box-sizing: border-box;left: 16px;top: 52px;font-size: 12px;color: white;">
                    <span style="vertical-align: middle;font-weight: bold;">{{inviteConfig.reward}} <img src="@/assets/common/moneyIcon.png" width="12px" style="vertical-align: middle;" /></span>
                  </span>
                </div>
                <div v-else style="position: relative;width: 94px;">
                  <img src="@/assets/images/indexInviteEvent004.gif" width="80" style="margin-top: 20px;" />
                  <span style="position: absolute;box-sizing: border-box;left: 20px;top: 58px;font-size: 12px;color: white;">
                    <span style="vertical-align: middle;font-weight: bold;">{{inviteConfig.reward}} <img src="@/assets/common/moneyIcon.png" width="12px" style="vertical-align: middle;" /></span>
                  </span>
                </div>
                <div style="text-align: center;font-size: 12px;color: white;">
                  {{$t('inviteEvent.i_invite')}} {{inviteConfig.inviteNum}} {{$t('inviteEvent.i_friends')}}
                </div>
              </div>
              <div :style="[{float: 'right'},{opacity: (getPercentage(inviteConfig.startNum, inviteConfig.inviteNum) <= 0 ? '0.5':'1')}]">
                <el-progress color="#FFE34F" :text-inside="true" :stroke-width="16" :percentage="getPercentage(inviteConfig.startNum, inviteConfig.inviteNum)" :format="format" style="margin-top: 18px;width: 200px;"></el-progress>
                <div style="overflow: hidden;font-size: 12px;color: white;">
                  <span style="float: left;margin-top: 6px;">{{inviteConfig.startNum}}</span>
                  <span style="float: right;margin-top: 6px;">{{inviteConfig.inviteNum}}</span>
                </div>
                <el-button round v-if="inviteNum.inviteNum >= inviteConfig.inviteNum && inviteConfig.getReward != true" @click="toReward(inviteConfig.id)" size="small" style="background-color: rgba(255, 227, 79, 1);color: black;font-size: 12px;margin-top: 6px;width: 100px;">{{$t('inviteEvent.i_Claim')}}</el-button>
                <el-button round v-else-if="inviteConfig.getReward == true" size="small" style="background-color: rgba(82, 82, 82, 1);margin-top: 6px;width: 100px;font-size: 12px;color: white;">{{$t('inviteEvent.i_received')}}</el-button>
                <el-button round v-else size="small" @click="drawer = true" style="background-color: rgba(195, 212, 252, 1);margin-top: 6px;width: 100px;font-size: 12px;color: black;">{{$t('inviteEvent.i_inviteNow')}}</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ inviteFriend.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        checkDialog: false,
        centerDialogVisible: false,
        tipsDialog: false,
        isActivation: false,
        inviteForm: {
          inviteCode: ""
        },
        drawer: false,
        inviteCode: "",
        inviteLink: "",
        inviteUidInviteCode: null,
        inviteNum: {
          inviteNum: 0,
          totalGoldNum: 0
        },
        inviteReward: 0,
        beInviteReward: 0,
        commission: 0,
        totalReward: 0,
        inviteEventDrawer: false,
        inviteConfig: [],
        inviteFriend: {
          appName: "",
          check: "",
          failMessage: "",
          appScheme: "",
          webAppId: 0
        }
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.inviteFriend.check == "login") {
          window.location.href = this.inviteFriend.appScheme + "login";
        } else {
          window.location.href = this.inviteFriend.appScheme;
        }
      },
      getPercentage(start, end) {
        // 当前进度的邀请数量
        var curNum = this.inviteNum.inviteNum - start;
        // 当前进度的总邀请数量
        var totalNum = end - start;
        // 当前进度
        var curPercentage = ((curNum < 0 ? 0 : curNum) / totalNum) * 100;
        return curPercentage >= 100 ? 100 : curPercentage;
      },
      getStyle(val, percentage) {
        if (val % 2 == 0 && percentage > 0) {
          return "inviteEvent0";
        } else if (val % 2 != 0 && percentage > 0) {
          return "inviteEvent1";
        } else if (val % 2 == 0 && percentage <= 0) {
          return "inviteEvent2";
        } else if (val % 2 != 0 && percentage <= 0) {
          return "inviteEvent3";
        } else {
          return "inviteEvent3";
        }
      },
      format(percentage) {
        return percentage == 100
          ? "✔"
          : percentage == 0
          ? ""
          : this.inviteNum.inviteNum;
      },
      getItNow() {
        if (this.inviteForm.inviteCode != null) {
          this.isLoading = true;
          this.$axios({
            url: "/webGift/checkInvite",
            method: "get",
            params: {
              inviteCode: this.inviteForm.inviteCode
            }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.code == 1) {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "success",
                center: true
              });
              this.centerDialogVisible = false;
              this.toInviteFriend();
            } else {
              this.$message({
                showClose: true,
                message: result.data.message,
                type: "error",
                center: true
              });
            }
          });
        }
      },
      copyVal(val) {
        // 创建一个 Input标签
        let oInput = document.createElement("input");
        oInput.value = val;
        document.body.appendChild(oInput);
        // 选择对象
        oInput.select();
        // 执行浏览器复制命令
        // 复制命令会将当前选中的内容复制到剪切板中
        // 如这里构建的 Input标签
        document.execCommand("Copy");
        this.$message({
          message: this.$t("common.i_copy"),
          type: "success",
          showClose: true,
          center: true
        });
        // 复制成功后再将构造的标签 移除
        oInput.remove();
      },
      toReward(id) {
        this.isLoading = true;
        this.$axios({
          url: "/webGift/getInviteReward",
          method: "get",
          params: {
            cfgId: id
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.toInviteFriend();
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      },
      toInviteFriend() {
        this.isLoading = true;
        this.$axios({
          url: "/webGift/inviteFriend",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.$store.state.goldNum = result.data.data.goldNum;
            this.inviteCode = result.data.data.inviteCode;
            this.inviteLink = result.data.data.inviteLink;
            this.inviteUidInviteCode = result.data.data.inviteUidInviteCode;
            this.inviteForm.inviteCode = result.data.data.inviteUidInviteCode;
            this.inviteNum = result.data.data.inviteNum;
            this.inviteReward = result.data.data.inviteReward;
            this.beInviteReward = result.data.data.beInviteReward;
            this.commission = result.data.data.commission;
            this.totalReward = result.data.data.totalReward;
            this.isActivation = result.data.data.isActivation;
            this.inviteConfig = result.data.data.inviteConfigList;
            this.inviteFriend.appName = result.data.data.appName;
            this.inviteFriend.webAppId = result.data.data.webAppId;
          } else if (result.data.code == -2) {
            this.inviteFriend.check = result.data.data.check;
            this.inviteFriend.failMessage = result.data.message;
            this.inviteFriend.appScheme = result.data.data.appScheme;
            this.checkDialog = true;
          }
        });
      }
    },
    created() {
      this.toInviteFriend();

      if (this.$route.params.inviteEventDrawer == true) {
        this.inviteEventDrawer = true;
      }
    }
  };
</script>

<style>
  #invite-friend-layout {
    width: 100%;
    height: 100%;
  }
  #invite-friend-layout .if-page-header {
    padding-top: 4%;
    padding-left: 4%;
    padding-right: 4%;
    overflow: hidden;
  }
  #invite-friend-layout .el-drawer {
    border-radius: 20px 20px 0 0 !important;
  }
  #invite-friend-layout .el-progress-bar {
    display: block !important;
  }
  #invite-friend-layout .el-progress-bar__outer {
    background-color: #ffffff !important;
  }
  #invite-friend-layout .el-progress-bar__innerText {
    color: black !important;
  }
  #invite-friend-layout .el-progress-bar__outer,
  #invite-friend-layout .el-progress-bar__inner {
    border-radius: 0 !important;
  }
  #invite-friend-layout .inviteEvent0 {
    background-color: rgba(47, 132, 105, 1);
  }
  #invite-friend-layout .inviteEvent1 {
    background-color: rgba(60, 154, 124, 1);
  }
  #invite-friend-layout .inviteEvent2 {
    background-color: rgba(33, 61, 128, 1);
  }
  #invite-friend-layout .inviteEvent3 {
    background-color: rgba(60, 88, 154, 1);
  }
</style>
